/* You can add global styles to this file, and also import other style files */

@import "~bootstrap/dist/css/bootstrap.css";
@import '~font-awesome/css/font-awesome.css';
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import '@fortawesome/fontawesome-free/css/all.css';
@import "~font-awesome/css/font-awesome.min.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
    font-family: 'Roboto', serif;
    font-size: 1rem;
}

a {
    text-decoration: none;
}

.table thead th {
    text-transform: capitalize;
    background-color: #2e3192;
    color: #fff;
}

.form-control {
    border-color: cornflowerblue;
}

small {
    font-size: 1rem;
}
